/* eslint-disable no-useless-computed-key */
import React from "react";
import classNames from "classnames";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { resetState } from "slices/authSlice";
import { useUserData } from "./AuthLayout";
import Version from "../../package.json";
const Header = ({ isSidebarOpen, toggleSidebar }) => {
  const dispatch = useDispatch();
  const { employeeData } = useUserData();

  const onLogout = () => {
    dispatch(resetState());
  };

  return (
    <nav
      className={classNames(
        [
          "flex",
          "items-center",
          "justify-between",
          "sm:px-8",
          "px-4",
          "h-16",
          "border-b",
          "top-0",
          "fixed",
          "bg-white",
          "z-[1]",
          "left-0",
          "shadow",
          "w-full",
          "left-0"
        ],
        { ["sm:w-calc sm:left-[250px]"]: isSidebarOpen }
      )}
    >
      <div className='flex items-center space-x-2 text-gray-500'>
        <button
          type='button'
          className='text-gray-600 border-transparent bg-transparent hover:bg-transparent hover:border-transparent'
          onClick={toggleSidebar}
        >
          <GiHamburgerMenu className='font-bold transition-transform hover:scale-110 hover:cursor-pointer' />
        </button>
        <div className='flex items-center justify-center font-bold'>Payroll</div>
        <div className='flex items-center justify-center font-bold'>
          <p className='text-[8px]'>v{Version.version}</p>
        </div>
      </div>
      <div className='flex items-center space-x-4'>
        <div></div>
        <div className='dropdown dropdown-bottom dropdown-end'>
          <label
            className='text-sm text-gray-400 flex items-center space-x-3 cursor-pointer'
            tabIndex={0}
          >
            <p>{employeeData?.name ?? ""}</p>
            {employeeData?.image ? (
              <img alt='profile-pic' src={employeeData?.image} className='rounded-full w-8 h-8' />
            ) : (
              <div className='rounded-full bg-primary-400 opacity-50 p-4' />
            )}
          </label>
          <ul
            tabIndex={0}
            className='mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52'
          >
            <li>
              <a href={"https://hr-apps.smtapps.net/login"} rel='noreferrer'>
                Back to Pagii HR
              </a>
            </li>
            <li onClick={onLogout}>
              <a href='/#logout' rel='noreferrer'>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
