import React, { lazy, useMemo } from "react";
import { Navigate, createBrowserRouter, defer, RouterProvider } from "react-router-dom";

import route from "./route";
import { useDispatch, useSelector } from "react-redux";
import Layout from "layouts/Layout";

import { setCurrentUser } from "slices/authSlice";
import { map } from "lodash";
import { USERS } from "services";
import AuthLayout from "layouts/AuthLayout";

// import My404Component from "pages/My404Component";

const Landing = lazy(() => import("pages"));
const Callback = lazy(() => import("pages/Callback"));

const ProtectedRoute = ({ children }) => {
  const { accessToken } = useSelector(({ auth }) => auth);

  if (accessToken) {
    return <Layout>{children}</Layout>;
  } else return <Navigate to='/' replace />;
};
const Routes = () => {
  const dispatch = useDispatch();
  const { decodedToken, accessToken } = useSelector(({ auth }) => auth);

  const RouteElements = useMemo(() => {
    return createBrowserRouter([
      {
        element: accessToken ? (
          // <Navigate to="/dashboard" replace />
          <Navigate to='/employees' replace />
        ) : (
          <Landing />
        ),
        path: "/"
      },
      {
        element: <Callback />,
        path: "/callback"
      },
      {
        element: <AuthLayout />,
        loader: async ({ signal }) => {
          try {
            let employeeData = null;
            if (decodedToken?.id) {
              const { data: userDetail } = await USERS.getCurrentUser(decodedToken?.id, signal);
              dispatch(setCurrentUser(userDetail));
              employeeData = userDetail;
            }
            return defer({
              employeeData
            });
          } catch (error) {
            return defer({
              employeeData: null
            });
          }
        },
        children: map(route, (val, key) => {
          const Component = val.element;
          return {
            key: key,
            path: val.path,
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            )
          };
        })
      }
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedToken?.id, decodedToken?.organization_id, dispatch]);

  return <RouterProvider router={RouteElements} />;
};

export default Routes;
