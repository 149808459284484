import React, { useState } from "react";
import classNames from "classnames";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [isOpen, setOpen] = useState(true);
  const toggleOpen = () => setOpen((curr) => !curr);

  return (
    <div className='h-full min-h-screen'>
      <div className='flex flex-1 overflow-hidden'>
        <Sidebar isSidebarOpen={isOpen} setOpen={setOpen}>
          {children}
        </Sidebar>
        <div
          className={classNames(["flex-auto", "w-0", "lg:ml-[250px]", "md:ml-[250px]"], {
            "!ml-0": !isOpen
          })}
        >
          <Header isSidebarOpen={isOpen} toggleSidebar={toggleOpen} />
          <main
            className='w-full h-full min-h-screen mt-16 bg-gray-50'
            style={{ transition: "margin-left .2s" }}
          >
            <div className='h-full lg:p-8 md:p-8 p-4'>{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
