import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const initialState = {
  accessToken: null,
  decodedToken: null,
  currentUser: null,
  currentOrg: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action?.payload;
      state.decodedToken = jwtDecode(action?.payload);
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action?.payload;
    },
    setCurrentOrg: (state, action) => {
      state.currentOrg = action?.payload;
    },
    resetState: () => {
      return initialState;
    }
  }
});

export const { setAccessToken, setCurrentUser, setCurrentOrg, resetState } = authSlice.actions;

export default authSlice.reducer;
