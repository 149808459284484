import React from "react";
import { Link } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";

const Breadcrumbs = ({ items }) => {
  return (
    <div className='text-sm breadcrumbs'>
      <ul>
        <li>
          <Link to='/'>
            <RiHome6Line className='hover:text-primary-500' />
          </Link>
        </li>
        {Array.isArray(items) && items.length
          ? items.map((item, key) => (
              <li key={key}>
                {item?.path ? (
                  <Link to={item.path} className='mb-0 first-line:text-sm hover:text-primary-400'>
                    {item.label}
                  </Link>
                ) : item?.isActive ? (
                  <p className='mb-0 text-primary-400 text-sm'>{item.label}</p>
                ) : (
                  <p className='mb-0 first-line:text-sm'>{item.label}</p>
                )}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
