import { isEmpty, isObject } from "lodash";

export const generateErrorMessage = (error) => {
  let message = "Something went wrong";
  let errors = error?.response?.data?.data;
  if (typeof errors === "string") {
    message = errors;
  } else if (typeof errors === "object") {
    if (!isEmpty(errors) && isObject(errors)) {
      message = Object.values(errors).join("<br />");
    }
  }
  return <span dangerouslySetInnerHTML={{ __html: message }} />;
};
