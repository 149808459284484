import { Spinner } from "components";
import { Suspense, useLayoutEffect } from "react";
import { useLoaderData, Await, useLocation, Outlet, useOutletContext } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const AuthLayout = () => {
  const loaderData = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await
        resolve={loaderData}
        errorElement={<div className='text-error'>Something went wrong!</div>}
        children={({ employeeData }) => {
          return <ScrollToTop>{<Outlet context={{ employeeData }} />}</ScrollToTop>;
        }}
      />
    </Suspense>
  );
};

export const useUserData = () => useOutletContext();

export default AuthLayout;
