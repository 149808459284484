import React, { useCallback, useMemo, useState } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Select } from "components";
import { Button } from "components/button";
import classNames from "classnames";

const defaultStep = 1;

const paginationObject = {
  current_page: 1,
  per_page: 10,
  total: 10,
  last_page: 0
};

const rowPerPageOptions = [
  {
    value: 10,
    label: "10"
  },
  {
    value: 25,
    label: "25"
  },
  {
    value: 50,
    label: "50"
  },
  {
    value: 100,
    label: "100"
  }
];

const generatePageNumbers = (length) => {
  if (length) return Array.from(Array(Number(length)), (_, x) => x + 1);
  return [];
};

const normalizePagination = (pagination) => {
  if ("totalPages" in pagination) {
    return {
      current_page: parseInt(pagination.page, 10),
      per_page: parseInt(pagination.perPage, 10),
      total: pagination.total,
      last_page: pagination.totalPages
    };
  } else if ("current_page" in pagination) {
    return pagination;
  }
  return paginationObject;
};

const Pagination = ({
  pagination = paginationObject,
  onChangeRowsPerPage,
  onChangePage,
  page,
  initLimit
}) => {
  const normalizedPagination = useMemo(() => normalizePagination(pagination), [pagination]);

  const [rowPerPage, setRowPerPage] = useState({
    value: initLimit ?? 10,
    label: initLimit ?? "10"
  });

  const rowsPerPage = useMemo(() => rowPerPage, [rowPerPage]);
  const changeRowsPerPage = useCallback(
    (page) => {
      setRowPerPage(page);
      onChangePage(1);
      if (onChangeRowsPerPage) onChangeRowsPerPage(page.value);
    },
    [onChangeRowsPerPage, onChangePage]
  );

  const pageNumbers = useMemo(() => {
    return generatePageNumbers(Math.ceil(normalizedPagination.total / rowPerPage.value));
  }, [rowPerPage, normalizedPagination]);

  const addPagination = useCallback(
    (s, f) => {
      let elms = [];
      var func = function (x) {
        onChangePage(x);
      };
      for (let index = s; index < f; index += 1) {
        elms.push(
          <Button
            key={index}
            size='sm'
            className={classNames(
              "bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold",
              { [`!border-black`]: page === index }
            )}
            onClick={() => func(index)}
          >
            {index}
          </Button>
        );
      }
      return elms;
    },
    [onChangePage, page]
  );

  const renderPaginationLabel = useCallback(() => {
    if (pageNumbers.length < defaultStep * 7) {
      return addPagination(1, pageNumbers.length + 1, page);
    } else if (page < defaultStep * 3) {
      return (
        <>
          {addPagination(1, defaultStep * 4, page)}
          <Button
            size='sm'
            className='bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold'
            disabled
          >
            ..
          </Button>
          {addPagination(pageNumbers.length - 2, pageNumbers.length + 1, page)}
        </>
      );
    } else if (page > pageNumbers.length - defaultStep * 2) {
      return (
        <>
          {addPagination(1, defaultStep * 4, page)}
          <Button
            size='sm'
            className='bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold'
            disabled
          >
            ..
          </Button>
          {addPagination(pageNumbers.length - 2, pageNumbers.length + 1, page)}
        </>
      );
    } else {
      return (
        <>
          <Button
            size='sm'
            className={classNames(
              "bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold",
              { [`!border-black`]: page === 1 }
            )}
            onClick={() => {
              onChangePage(1);
            }}
          >
            1
          </Button>
          <Button
            size='sm'
            className='bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold'
            disabled
          >
            ..
          </Button>
          {addPagination(page - defaultStep, page + (defaultStep + 1), page)}
          <Button
            size='sm'
            className='bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold'
            disabled
          >
            ..
          </Button>
          <Button
            size='sm'
            className={classNames(
              "bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold",
              { [`!border-black`]: page === pageNumbers.length }
            )}
            onClick={() => {
              onChangePage(pageNumbers.length);
            }}
          >
            {pageNumbers.length}
          </Button>
        </>
      );
    }
  }, [page, pageNumbers, addPagination, onChangePage]);

  const PaginationText = useMemo(() => {
    return normalizedPagination.total > 0 ? (
      <p className='mb-0 text-gray-600 text-sm'>{`Showing ${
        (page - 1) * rowsPerPage.value || 1
      } - ${
        page <= 1 && normalizedPagination.total <= rowsPerPage.value
          ? normalizedPagination.total
          : page * rowsPerPage.value
      } from ${normalizedPagination.total} datas.`}</p>
    ) : null;
  }, [rowsPerPage.value, page, normalizedPagination.total]);

  return (
    <div className='flex flex-wrap gap-3 items-center py-4 justify-between border-t-[1px]'>
      <div className='flex space-x-4 items-center'>
        {pageNumbers?.length > 0 && (
          <Select
            value={rowsPerPage}
            optionsData={rowPerPageOptions}
            onChange={changeRowsPerPage}
            menuPortalTarget={document.body}
          />
        )}
        {PaginationText}
      </div>
      <div className='btn-group'>
        {pageNumbers?.length > 0 && (
          <Button
            size='sm'
            className='prevbtn bg-white hover:bg-white hover:text-gray-700 text-gray-700 border-gray-300 text-sm font-semibold btn-outline'
            startIcon={<BsArrowLeftShort size={20} />}
            disabled={normalizedPagination.current_page <= 1}
            onClick={() => {
              onChangePage(page - 1);
            }}
          >
            Previous
          </Button>
        )}
        {renderPaginationLabel()}
        {pageNumbers?.length > 0 && (
          <Button
            size='sm'
            className='bg-white hover:bg-white hover:text-gray-700 text-gray-700 border-gray-300 text-sm font-semibold btn-outline'
            endIcon={<BsArrowRightShort size={20} />}
            disabled={normalizedPagination.current_page >= normalizedPagination.last_page}
            onClick={() => {
              onChangePage(page + 1);
            }}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(Pagination);
