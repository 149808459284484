import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistCombineReducers,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import rootReducer from "./root-reducer";

const persistConfig = {
  key: "pagii-payroll",
  storage,
  whitelist: ["auth"]
};

if (process.env.REACT_APP_NODE_ENV === "production") {
  persistConfig.transforms = [
    encryptTransform({
      secretKey: `pagii-payroll`,
      onError: (error) => {
        console.log(error);
      }
    })
  ];
}

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

const store = configureStore({
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persistor = persistStore(store);

export { store, persistor };
