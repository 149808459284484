import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { isEmpty } from "lodash";
import { store } from "app/store";
import { generateErrorMessage } from "utils/generate-error-message";

const createAxiosInstance = (baseURL) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    const token = store?.getState()?.auth?.accessToken;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (response?.data?.error && !isEmpty(response.data.error)) {
        enqueueSnackbar(generateErrorMessage(response), {
          autoHideDuration: 2500,
          variant: "error"
        });
        return Promise.reject(response);
      }
      return response;
    },
    (error) => {
      if (axios.isCancel(error)) return;
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.href = "";
        return;
      }
      enqueueSnackbar(generateErrorMessage(error), {
        autoHideDuration: 2500,
        variant: "error"
      });
      return Promise.reject(error);
    }
  );

  return instance;
};

const axiosInstance = createAxiosInstance(process.env.REACT_APP_API_URL);
export const hrAxiosInstance = createAxiosInstance(process.env.REACT_APP_HR_API_URL);

export default axiosInstance;
