import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Spinner } from "components";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import Pagination from "./Pagination";

const CustomTable = ({
  columns = [],
  dataSource = [],
  isLoading = false,
  stripped = false,
  wrapperClassName = "",
  headerClassName = "",
  className = "",
  layout = "auto",
  pagination = undefined,
  onChangeRowsPerPage = undefined,
  onChangePage = undefined,
  initLimit = undefined,
  page = 1,
  usePagination = true,
  order = "asc",
  sort = undefined
}) => {
  return (
    <Fragment>
      <div
        className={classNames({ [`overflow-x-auto overflow-y-hidden`]: layout === "fixed" }, [
          ...wrapperClassName.split(" ")
        ])}
      >
        <table
          className={classNames(
            ["table min-w-full"],
            { "table-auto": layout === "auto" },
            { "table-fixed": layout === "fixed" },
            {
              "table-striped": stripped
            },
            [...className.split(" ")]
          )}
        >
          <TableHeader
            sort={sort}
            order={order}
            items={columns}
            headerClassName={headerClassName}
          />
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length}>
                  <div className='relative w-100 h-[300px]'>
                    <Spinner className='text-[40px]' />
                  </div>
                </td>
              </tr>
            ) : (
              <TableRow columns={columns} datas={dataSource} />
            )}
          </tbody>
        </table>
      </div>
      {usePagination && (
        <Pagination
          pagination={pagination}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          page={page}
          initLimit={initLimit}
        />
      )}
    </Fragment>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  isLoading: PropTypes.bool,
  stripped: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  className: PropTypes.string,
  layout: PropTypes.oneOf(["auto", "fixed"]),
  sort: PropTypes.string,
  order: PropTypes.string,
  pagination: PropTypes.object,
  onChangeRowsPerPage: PropTypes.func,
  onChangePage: PropTypes.func,
  page: PropTypes.number,
  initLimit: PropTypes.number,
  usePagination: PropTypes.bool
};

export default React.memo(CustomTable);
