import axiosInstance from "app/axios-interceptor";

export const getMonthlyReport = (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `payroll/export?${queryString}`;
  return axiosInstance.defaults.baseURL + url;
};
export const generateReport = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const { data } = await axiosInstance.get(`payroll/export?${queryString}`);
  return data;
};
export const getMonthlyReportList = async (params, signal) => {
  const { data } = await axiosInstance.get(`payroll/export-list`, {
    params,
    signal
  });
  return data;
};
