import React from "react";
import { BiSearch } from "react-icons/bi";

const Search = ({ placeholder = "Search ...", onChange, value,title }) => {
  return (
    <div className="flex items-center rounded-md border-gray-200 px-4 space-x-3 border-solid border-[1px] bg-white">
      <BiSearch className="text-gray-400" size={20} title={title} />
      <input
        className="bg-white text-gray-400 w-full text-sm p-2 outline-none border-transparent focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-transparent"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default Search;
