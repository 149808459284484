import axiosInstance from "app/axios-interceptor";

export const getEmployeeDeductions = async (params, signal) => {
  const { data } = await axiosInstance.get(`employee-deduction`, { params, signal });
  return data;
};

export const getEmployeeDeductionDetail = async (id, params, signal) => {
  const { data } = await axiosInstance.get(`employee-deduction/${id}`, {
    params,
    signal
  });
  return data;
};
export const createEmployeeDeduction = async (payload) => {
  const { data } = await axiosInstance.post(`employee-deduction`, payload);
  return data;
};
export const updateEmployeeDeduction = async (id, payload) => {
  const { data } = await axiosInstance.put(`employee-deduction/${id}`, payload);
  return data;
};
export const deleteEmployeeDeduction = async (id) => {
  const { data } = await axiosInstance.delete(`employee-deduction/${id}`);
  return data;
};
