import axiosInstance from "app/axios-interceptor";

export const getEmployeeAllowances = async (params, signal) => {
  const { data } = await axiosInstance.get(`employee-allowance`, {
    params,
    signal
  });
  return data;
};

export const getEmployeeAllowanceDetail = async (id, params, signal) => {
  const { data } = await axiosInstance.get(`employee-allowance/${id}`, {
    params,
    signal
  });
  return data;
};
export const createEmployeeAllowance = async (payload) => {
  const { data } = await axiosInstance.post(`employee-allowance`, payload);
  return data;
};
export const updateEmployeeAllowance = async (id, payload) => {
  const { data } = await axiosInstance.put(`employee-allowance/${id}`, payload);
  return data;
};
export const deleteEmployeeAllowance = async (id) => {
  const { data } = await axiosInstance.delete(`employee-allowance/${id}`);
  return data;
};
