import axiosInstance from "app/axios-interceptor";

export const getEmployeeTaxes = async (params, signal) => {
  const { data } = await axiosInstance.get(`employee-tax`, { params, signal });
  return data;
};

export const getEmployeeTaxDetail = async (id, params, signal) => {
  const { data } = await axiosInstance.get(`employee-tax/${id}`, {
    params,
    signal
  });
  return data;
};
export const createEmployeeTax = async (payload) => {
  const { data } = await axiosInstance.post(`employee-tax`, payload);
  return data;
};
export const updateEmployeeTax = async (id, payload) => {
  const { data } = await axiosInstance.put(`employee-tax/${id}`, payload);
  return data;
};
export const deleteEmployeeTax = async (id) => {
  const { data } = await axiosInstance.delete(`employee-tax/${id}`);
  return data;
};
