import axiosInstance from "app/axios-interceptor";

export const getDeductions = async (params, signal) => {
  const { data } = await axiosInstance.get(`deduction`, { params, signal });
  return data;
};

export const getDeductionDetail = async (id, params, signal) => {
  const { data } = await axiosInstance.get(`deduction/${id}`, {
    params,
    signal
  });
  return data;
};
export const createDeduction = async (payload) => {
  const { data } = await axiosInstance.post(`deduction`, payload);
  return data;
};
export const updateDeduction = async (id, payload) => {
  const { data } = await axiosInstance.put(`deduction/${id}`, payload);
  return data;
};
export const deleteDeduction = async (id) => {
  const { data } = await axiosInstance.delete(`deduction/${id}`);
  return data;
};
