import axiosInstance from "app/axios-interceptor";

export const getCutOffPeriod = async (params, signal) => {
  const { data } = await axiosInstance.get(`cut-off-periode`, {
    params,
    signal
  });
  return data;
};
export const updateCutOffPeriod = async (id, payload) => {
  const { data } = await axiosInstance.put(`cut-off-periode/${id}`, payload);
  return data;
};

export const getMonthlyReportList = async (params, signal) => {
  const { data } = await axiosInstance.get(`monthly-report/list`, {
    params,
    signal
  });
  return data;
};
export const getMonthlyReport = async (params, signal) => {
  const { data } = await axiosInstance.get(`monthly-report`, {
    params,
    signal
  });
  return data;
};

export const updateMonthlyReport = async (id, payload) => {
  const { data } = await axiosInstance.put(`monthly-report/${id}`, payload);
  return data;
};
