import axiosInstance from "app/axios-interceptor";

export const getAllowances = async (params, signal) => {
  const { data } = await axiosInstance.get(`allowance`, { params, signal });
  return data;
};

export const getAllowanceDetail = async (id, params, signal) => {
  const { data } = await axiosInstance.get(`allowance/${id}`, {
    params,
    signal
  });
  return data;
};
export const createAllowance = async (payload) => {
  const { data } = await axiosInstance.post(`allowance`, payload);
  return data;
};
export const updateAllowance = async (id, payload) => {
  const { data } = await axiosInstance.put(`allowance/${id}`, payload);
  return data;
};
export const deleteAllowance = async (id) => {
  const { data } = await axiosInstance.delete(`allowance/${id}`);
  return data;
};
