import { FaFileExport, FaUsers } from "react-icons/fa6";
import { GoChecklist } from "react-icons/go";
import { MdListAlt, MdSettings } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { PiListChecksBold } from "react-icons/pi";

const MENU_ITEMS = [
  // {
  //   icon: <RiBookReadLine className="w-[18px] h-[20px]" />,
  //   id: "dashboard",
  //   label: "Dashboard",
  //   path: "/dashboard",
  // },
  {
    icon: <FaUsers className='w-[18px] h-[20px]' />,
    id: "employees",
    label: "Employees",
    path: "/employees"
  },
  {
    icon: <GiReceiveMoney className='w-[18px] h-[20px]' />,
    id: "allowances",
    label: "Allowances",
    path: "/allowances"
  },
  {
    icon: <MdListAlt className='w-[18px] h-[20px]' />,
    id: "deductions",
    label: "Deductions",
    path: "/deductions"
  },
  {
    icon: <GoChecklist className='w-[18px] h-[20px]' />,
    id: "taxes",
    label: "Taxes",
    path: "/taxes"
  },
  {
    icon: <PiListChecksBold className='w-[18px] h-[20px]' />,
    id: "payslip-history",
    label: "Payslip History",
    path: "/payslip-history"
  },
  {
    icon: <FaFileExport className='w-[18px] h-[20px]' />,
    id: "export-monthly-report",
    label: "Export Monthly Report",
    path: "/export-monthly-report"
  },
  {
    icon: <MdSettings className='w-[18px] h-[20px]' />,
    id: "general-settings",
    label: "General Settings",
    children: [
      {
        id: "cut-off-period",
        label: "Cut Off Period",
        path: "/general-settings/cut-off-period"
      },
      {
        id: "monthly-report",
        label: "Monthly Report",
        path: "/general-settings/monthly-report"
      }
    ]
  }
];

export default MENU_ITEMS;
