import React from "react";
import classNames from "classnames";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const TableHeader = ({ items, headerClassName, sort, order }) => {
  const getValue = (value) => {
    if (typeof value === "string") return value;
    if (typeof value === "function") return value();
    return "";
  };

  if (!items.length) return null;
  return (
    <thead className={headerClassName}>
      <tr>
        {items.map((item, key) => {
          return (
            <th
              key={key}
              className={classNames(
                ["bg-primary-400/10", "text-sm", "font-semibold", "capitalize"],
                { "cursor-pointer": item?.sortable },
                { "!relative": key === 0 },
                { "md:sticky": Boolean(item?.fixed) },
                { "md:right-0": item?.fixed && item?.fixed === "right" },
                { "md:left-0": item?.fixed && item?.fixed === "left" },
                { "!rounded-tl-none": key === 0 },
                { "!rounded-tr-none": key === items.length - 1 },
                item.columnClassName && [...item.columnClassName.split(" ")]
              )}
              onClick={() => {
                if (item?.sortable) {
                  let tempSortOrder = order;
                  if (tempSortOrder === "asc") tempSortOrder = "desc";
                  else tempSortOrder = "asc";
                  item.sorter(item.dataIndex, tempSortOrder);
                }
              }}
            >
              {item?.sortable && item?.sorter ? (
                <div
                  className={classNames("gap-2 flex", {
                    "justify-center":
                      item?.columnClassName && item?.columnClassName.includes("text-center")
                  })}
                >
                  {getValue(item.title)}
                  {sort === item.dataIndex ? (
                    order === "asc" ? (
                      <BsArrowUpShort size={20} className='text-gray-700 cursor-pointer' />
                    ) : (
                      <BsArrowDownShort size={20} className='text-gray-700 cursor-pointer' />
                    )
                  ) : null}
                </div>
              ) : (
                getValue(item.title)
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default React.memo(TableHeader);
