import axiosInstance from "app/axios-interceptor";

export const getTaxes = async (params, signal) => {
  const { data } = await axiosInstance.get(`tax`, { params, signal });
  return data;
};

export const getTaxDetail = async (id, params, signal) => {
  const { data } = await axiosInstance.get(`tax/${id}`, { params, signal });
  return data;
};
export const createTax = async (payload) => {
  const { data } = await axiosInstance.post(`tax`, payload);
  return data;
};
export const updateTax = async (id, payload) => {
  const { data } = await axiosInstance.put(`tax/${id}`, payload);
  return data;
};
export const deleteTax = async (id) => {
  const { data } = await axiosInstance.delete(`tax/${id}`);
  return data;
};
