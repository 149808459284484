import axiosInstance from "app/axios-interceptor";

export const getContracts = async (params, signal) => {
  const { data } = await axiosInstance.get(`contract`, { params, signal });
  return data;
};

export const getContractDetail = async (id, params, signal) => {
  const { data } = await axiosInstance.get(`contract/${id}`, {
    params,
    signal
  });
  return data;
};
export const createContract = async (payload) => {
  const { data } = await axiosInstance.post(`contract`, payload);
  return data;
};
export const updateContract = async (id, payload) => {
  const { data } = await axiosInstance.put(`contract/${id}`, payload);
  return data;
};
export const deleteContract = async (id) => {
  const { data } = await axiosInstance.delete(`contract/${id}`);
  return data;
};
