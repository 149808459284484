/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { IoMdClose } from "react-icons/io";

import image from "assets/pagii-payroll.svg";
import menus from "constants/menus";

const SubMenu = (props) => {
  const location = useLocation();
  const { menuKey, isOpen, icon, label, id } = props;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    if (!isMenuOpen && !isSet) {
      const idx = props.children.findIndex((x) => location.pathname === x.path);
      if (idx > -1) setMenuOpen(true);
      else if (location.pathname === "/" && id === "dashboard") setMenuOpen(true);
    }
  }, [location.pathname, props.children, isMenuOpen, isSet, id]);

  return (
    <li
      key={menuKey}
      className='cursor-pointer collapse collapse-arrow !outline-none !rounded-none'
    >
      <input
        type='checkbox'
        className='!min-h-0 w-full h-full'
        checked={isMenuOpen}
        onChange={() => {
          setIsSet(true);
          setMenuOpen((curr) => !curr);
        }}
      />
      <div
        className={`collapse-title !min-h-0 !p-2 font-semibold text-sm flex items-center ${
          !isOpen ? "justify-center" : ""
        } hover:border-r-2 hover:bg-primary-400/20 hover:border-r-primary-400`}
      >
        {icon}
        {isOpen && <span className='ml-3'>{label}</span>}
      </div>
      <div className='collapse-content mt-[10px] !p-0 space-y-[10px] ml-6'>
        {props.children.map((child, key) => (
          <Link
            key={key}
            to={child.path}
            className={classNames(
              "flex space-x-2 items-center py-2 px-3 hover:border-r-2 hover:bg-primary-400/20 hover:border-r-primary-400",
              {
                "border-r-2 bg-primary-400/20 border-r-primary-400":
                  location.pathname.includes(child.path) ||
                  (location.pathname === "/" && child.id === "dashboard")
              }
            )}
          >
            <div className='font-semibold text-sm'>{child.label}</div>
          </Link>
        ))}
      </div>
    </li>
  );
};

const Sidebar = ({ isSidebarOpen, setOpen }) => {
  const location = useLocation();

  return (
    <aside
      className={classNames(
        [
          "fixed left-0 top-0 bottom-0 flex-col sm:flex overflow-hidden bg-white flex-[0 0 0px] transition-[all .2s] z-[2] max-w-full w-full shadow-md shadow-[#0000001F]"
        ],
        {
          ["flex-[0 0 250px] sm:max-w-[250px] sm:min-w-[250px] sm:w-[250px]"]: isSidebarOpen,
          ["!max-w-0"]: !isSidebarOpen
        }
      )}
    >
      <IoMdClose
        size={25}
        className='cursor-pointer absolute right-[15px] top-[15px] sm:hidden block'
        onClick={() => setOpen(false)}
      />
      <div className='mt-3 flex relative items-center'>
        <div className='flex-1 flex p-3 py-0 items-center mb-4'>
          <div className='flex gap-4 items-center'>
            <img src={image} alt='logo' className='w-auto h-12 object-contain' />
          </div>
        </div>
      </div>
      <ul className='flex-2 space-y-2 m-3 overflow-y-auto'>
        {menus.map((menu, key) => {
          if (menu.children?.length > 0) {
            return <SubMenu key={key} menuKey={key} isOpen={isSidebarOpen} {...menu} />;
          }
          return (
            <li key={key} className='cursor-pointer'>
              <Link
                to={menu.path}
                className={`p-2 font-semibold text-sm flex items-center hover:border-r-2 hover:bg-primary-400/20 hover:border-r-primary-400 ${
                  (location?.pathname.includes(menu.path) ||
                    (location.pathname === "/" && menu.id === "dashboard")) &&
                  "border-r-2 bg-primary-400/20 border-r-primary-400"
                }`}
              >
                {menu.icon}
                <span className='ml-3'>{menu.label}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
