import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Routes from "routes";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import Modal from "react-modal";
import { PersistGate } from "redux-persist/integration/react";

import { Spinner } from "components";

import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-lazy-load-image-component/src/effects/blur.css";

import { store, persistor } from "app/store";

const root = ReactDOM.createRoot(document.getElementById("root"));

Modal.setAppElement("#root");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0
    }
  }
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Spinner />}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              maxSnack={3}
              disableWindowBlurListener
              autoHideDuration={3000}
            >
              <Routes />
            </SnackbarProvider>
          </QueryClientProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
