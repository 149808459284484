import { lazy } from "react";

// Dashboard
// const Dashboard = lazy(() => import('pages/Dashboard'));

// Employees
const Employees = lazy(() => import("pages/Employees"));
const EmployeeDetail = lazy(() => import("pages/Employees/Detail"));

// Deductions
const Deductions = lazy(() => import("pages/Deductions"));

// Taxes
const Taxes = lazy(() => import("pages/Taxes"));

// Allowances
const Allowances = lazy(() => import("pages/Allowances"));

// GeneralSettings
const CutOffPeriod = lazy(() => import("pages/GeneralSettings/CutOffPeriod"));
const MonthlyReport = lazy(() => import("pages/GeneralSettings/MonthlyReport"));

// PayslipHistory
const PayslipHistory = lazy(() => import("pages/PayslipHistory"));
const PayslipHistoryDetail = lazy(() => import("pages/PayslipHistory/detail"));

const ExportMonthlyReport = lazy(() => import("pages/ExportMonthlyReport"));

const routesName = [
  // PayslipHistory
  {
    routeType: "private",
    path: "/payslip-history",
    element: PayslipHistory,
    exact: true
  },
  {
    routeType: "private",
    path: "/payslip-history/:employeeId",
    element: PayslipHistoryDetail,
    exact: true
  },

  // DASHBOARD
  // {
  //   routeType: "private",
  //   path: "/dashboard",
  //   element: Dashboard,
  //   exact: true,
  // },

  // EMPLOYEES
  {
    routeType: "private",
    path: "/employees",
    element: Employees,
    exact: true
  },
  {
    routeType: "private",
    path: "/employees/:employeeId",
    element: EmployeeDetail,
    exact: true
  },

  // DEDUCTIONS
  {
    routeType: "private",
    path: "/deductions",
    element: Deductions,
    exact: true
  },

  // TAXES
  {
    routeType: "private",
    path: "/taxes",
    element: Taxes,
    exact: true
  },

  // Allowances
  {
    routeType: "private",
    path: "/allowances",
    element: Allowances,
    exact: true
  },

  // ExportMonthlyReport
  {
    routeType: "private",
    path: "/export-monthly-report",
    element: ExportMonthlyReport,
    exact: true
  },

  // GeneralSettings
  {
    routeType: "private",
    path: "/general-settings/cut-off-period",
    element: CutOffPeriod,
    exact: true
  },
  {
    routeType: "private",
    path: "/general-settings/monthly-report",
    element: MonthlyReport,
    exact: true
  }
];

export default routesName;
