import React, { forwardRef } from "react";
import ReactDatepicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import classNames from "classnames";
import { createPortal } from "react-dom";

const CalendarContainer = ({ children }) =>
  children
    ? createPortal(
        React.cloneElement(children, {
          className: "react-popper"
        }),
        document.body
      )
    : null;

const CustomInput = forwardRef((props, ref) => {
  const { value, onClick, onClear, isError, placeholderText, disabled } = props;
  const defaultClassName =
    "flex flex-1 items-center !px-4 !py-2 border-[1px] border-gray-200 rounded-md bg-white w-full text-sm";
  return (
    <div
      className={classNames(...defaultClassName.split(" "), {
        [`!border-red-500`]: isError,
        [`!cursor-default`]: disabled,
        [`!bg-[#EFEFEF]`]: disabled
      })}
    >
      <input
        value={value}
        ref={ref}
        className='!p-0 w-full outline-none border-transparent text-gray-800 text-sm focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-transparent disabled:bg-[#EFEFEF]'
        readOnly
        placeholder={placeholderText}
        disabled={disabled}
        onClick={onClick}
      />
      {value && !disabled ? (
        <IoMdCloseCircle className='text-gray-400' size={20} cursor='pointer' onClick={onClear} />
      ) : (
        <AiOutlineCalendar
          size={20}
          className={classNames("text-gray-400", {
            "cursor-pointer": !disabled
          })}
          onClick={onClick}
        />
      )}
    </div>
  );
});

const Datepicker = ({
  placeholder,
  value,
  onChange,
  disabled = false,
  onClear,
  invalid = false,
  ...props
}) => {
  return (
    <ReactDatepicker
      popperContainer={CalendarContainer}
      disabledKeyboardNavigation
      placeholderText={placeholder}
      value={value}
      selected={value}
      onChange={onChange}
      disabled={disabled}
      dateFormat='yyyy-MM-dd'
      customInput={
        <CustomInput
          onClear={onClear}
          isError={invalid}
          placeholderText={placeholder}
          disabled={disabled}
        />
      }
      {...props}
    />
  );
};

export default Datepicker;
