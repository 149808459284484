import axiosInstance from "app/axios-interceptor";

export const getPayrolls = async (params, signal) => {
  const { data } = await axiosInstance.get(`payroll`, { params, signal });
  return data;
};

export const getEmployeePayroll = async (params, signal) => {
  const { data } = await axiosInstance.get(`payroll/${params.employee_id}`, { params, signal });
  return data;
};

export const generatePayslip = async (params, signal) => {
  const { data } = await axiosInstance.get(`payroll/generate-payslip`, { params, signal });
  return data;
};
